<template>
  <b-modal
    id="modal-order-view"
    ref="modal"
    v-model="open"
    size="ov-custom"
    centered
    hide-footer
    title="Manage Status"
  >
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-row class="mb-2">
        <b-col
          cols="12"
          lg="3"
        >
          <div>
            Status Name
          </div>
        </b-col>
        <b-col>
          <order-status-badge
            class="status-bar"
            style="width:100%"
            :status-key="statusOptions.key"
          />
        </b-col>
      </b-row>
      <ValidationObserver
        ref="manageStatus"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickManageStatus)"
        >
          <b-row class="mb-2">
            <b-col cols="12">
              <b-form-group
                label="Custom Name"
                label-for="h-name"
                label-cols-md="3"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="Custom name"
                  rules="required"
                >
                  <b-form-input
                    id="Custom Name"
                    v-model="statusOptions.name"
                    placeholder="Enter Custom Name"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- <b-row class="mb-3">
            <b-col md="3">Action</b-col>
            <b-col md="9">
              <b-row>
                <b-col md="4" v-for="(value, key) in statusOptions.actions" :key="value">
                  <b-form-checkbox
                    v-modal="statusOptions.actions[key]"
                  >
                    {{ formatInput(key) }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-col>
          </b-row> -->
          <b-row
            v-if="statusOptions.key === 'key_11'"
            class="mb-4"
          >
            <b-col cols="3">
              Validation
            </b-col>
            <b-col cols="9">
              <b-form-checkbox
                id="checkbox-1"
                v-model="statusOptions.settings.any_destination_branch_enabled"
                class="mb-3"
              >
                Allow any destination branch
              </b-form-checkbox>
              <b-form-checkbox
                id="checkbox-2"
                v-model="statusOptions.settings.false_branch_update_enabled"
                class="mb-3"
              >
                Allow False Branch Update
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-col
            class="mb-2"
            offset-md="4"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="clearAll"
            >
              Clear
            </b-button>
          </b-col>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal,
  BOverlay,
  VBModal,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
import OrderStatusBadge from '@core/components/status/OrderStatusBadge.vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Ripple from 'vue-ripple-directive'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const OrderRepository = RepositoryFactory.get('order')

export default {
  name: 'ManageStatus',
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BModal,
    BOverlay,
    BRow,
    BCol,
    OrderStatusBadge,
    BFormGroup,
    BForm,
    BFormInput,
    BFormCheckbox,
    // BFormCheckboxGroup,
    BButton,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      open: false,
      signatureOpen: false,
      modalLoading: false,
      loading: false,
      id: null,
      items: {},
      perPage: 10,
      statusOptions: {},
    }
  },
  computed: {
    filterobject() {
      return Object.keys(this.statusOptions.actions).filter(key => this.statusOptions.actions[key])
    },
  },
  methods: {
    formatInput(key) {
      return key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
    },
    async fetchStatusDetails() {
      try {
        const { data } = (await OrderRepository.updateStatusId(this.id)).data
        this.statusOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async onClickManageStatus() {
      try {
        this.loading = true
        if (!this.statusOptions.settings.any_destination_branch_enabled && this.statusOptions.settings.false_branch_update_enabled) {
          this.showErrorMessage("Cannot enable the 'False Branch Update' validation without selecting the 'Allow any destination branch' validation.")
          this.loading = false
          return
        }
        const data = {
          status_data: this.statusOptions,
          name: this.statusOptions.name,
          settings: {
            any_destination_branch_enabled: this.statusOptions.settings.any_destination_branch_enabled,
            false_branch_update_enabled: this.statusOptions.settings.false_branch_update_enabled,
          },
        }
        const res = await OrderRepository.updateStatusIdupdate(this.statusOptions.id, data)
        if (res.status === 200) {
          this.open = false
          this.showSuccessMessage(`${this.statusOptions.original_name}  updated successfully`)
          this.$parent.onClickRefresh()
          localStorage.removeItem('status_data')
        } else {
          this.showErrorMessage(`Cannot Update ${this.statusOptions.original_name}  Status Settings`)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async openModal(id) {
      this.open = true
      this.id = id
      this.modalLoading = true
      this.modalLoading = false
      await this.fetchStatusDetails()
    },
  },
}
</script>
<style scoped>
b-form-checkbox{
  margin-bottom: 10px;
}
  @media (min-width: 767px) {
    .status-bar {
      padding : 1rem;
    }
  }
.custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.45rem;
    margin-bottom: 15px;
  }
</style>
