<template>
  <div>
    <b-card-actions
      action-collapse
      collapsed
      title="Filters"
    >
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Status"
            label-for="Status"
          >
            <v-select
              v-model="selectedStatusFilters"
              :options="statusOptions"
              :reduce="option => option.key"
              label="name"
              placeholder="Select Status"
              multiple
              deselect-from-dropdown
            >
              <template
                v-slot:option="option"
              >
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <filter-buttons
            :filter-data="filters"
            :apply-filters="applyFilters"
            :clear-filters="clearFilters"
          />
        </b-col>
      </b-row>
    </b-card-actions>
    <!-- table -->
    <b-card
      no-body
    >
      <div
        class="m-2"
      >
        <b-row>
          <b-col
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <ReportTableButtons
              :items="items"
              :json_fieldz="json_fields"
              :name="`Order Status - Page ${page}.xls`"
              :bulkname="`Order Status.xls`"
              :fetch="getStatusListNoPagination"
              @refresh="onClickRefresh"
            />
          </b-col>
        </b-row>
      </div>
      <b-overlay
        id="overlay-background"
        variant="light"
        opacity="0.30"
        blur="10px"
        rounded="sm"
      >
        <b-table
          :per-page="perPage"
          :fields="fields"
          :items="items"
          hover
          responsive
          striped
          show-empty
          sticky-header="100vh"
          class="hide-vertical-scroll"
        >
          <template #empty>
            <TableDataFetching
              :rows="items"
              :data-loading="dataLoading"
            />
          </template>
          <template #cell(original_name)="data">
            <div class="d-flex align-items-start">
              {{ data.item.original_name }}
            </div>
          </template>
          <template #cell(icon)="data">
            <div class="width-150">
              <b-avatar
                size="32"
                :variant="'light-'+data.item.color"
              >
                <feather-icon
                  size="20"
                  :icon="data.item.icon"
                  color="danger"
                />
              </b-avatar>
            </div>
          </template>
          <template #cell(custom_name)="data">
            <div class="d-flex align-items-start">
              {{ data.item.name }}
            </div>
          </template>
          <template #cell(updated_at)="data">
            <div class="d-flex align-items-start">
              {{ __dateTimeFormatter(data.item.updated_at) }}
            </div>
          </template>
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="12"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-can="'show-orders'"
                @click="onClickViewOrder(data.item.id)"
              >
                <feather-icon icon="EyeIcon" />
                Manage Setting
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-sm-start"
            >
              <label>Per page</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-1 width-100 mr-1"
              />
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              md="9"
              class="d-flex align-items-center justify-content-sm-end"
            >
              <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
              <b-pagination
                v-model="page"
                :total-rows="(meta.total)? meta.total : 0"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <manage-status ref="ManageStatus" />
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BDropdown,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BFormGroup,
  BDropdownItem,
  BPagination,
  VBTooltip,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Filter from '@core/mixins/filter'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import store from '@/store/index'
import ManageStatus from '@/components/AllOrders/ManageStatus.vue'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const orderRepository = RepositoryFactory.get('order')

export default {
  components: {
    ManageStatus,
    BTable,
    BAvatar,
    BDropdown,
    BRow,
    BCol,
    BCard,
    BOverlay,
    BFormGroup,
    BDropdownItem,
    vSelect,
    BPagination,
    BCardActions,
    FilterButtons,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  props: {
    type: {
      type: String,
      default: 'Order',
    },
  },
  data() {
    return {
      filters: [],
      total: 0,
      meta: {},
      dataLoading: false,
      selectedStatusFilters: [],
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      items: [],
      pageLength: 20,
      downloadReportName: 'Order-Status',
      // Start BS Table
      filter: null,
      filterOn: [],
      dir: false,
      fields: [
        {
          key: 'original_name',
          label: 'ORIGINAL STATUS',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'icon',
          label: 'Status Icon',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'custom_name',
          label: 'Custom Name',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'updated_at',
          label: 'Updated AT',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'action',
          label: 'Action',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-center',
        },
      ],
      json_fields: {
        'Original Name': {
          field: 'original_name',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => `"${value}"`,
        },
        'Custom Name': {
          field: 'name',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => `"${value}"`,
        },
        'Updated Date': {
          field: 'updated_at',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    options: {
      handler() {
        this.getStatusList()
      },
    },
    deep: true,
    page() {
      this.getStatusList()
    },
    perPage() {
      this.getStatusList()
    },
    selectedStatusFilters: {
      async handler(val) {
        let keyList = ''
        val.forEach(key => {
          keyList += `${key},`
        })
        this.filters = {
          status: keyList,
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.readOrderStatuses()
    this.getStatusList()
    this.totalRows = this.rows.length
  },
  methods: {
    async readOrderStatuses() {
      this.statusOptions = localStorage.getItem('order_statuses') ? JSON.parse(localStorage.getItem('order_statuses')) : (await orderRepository.getFullStatusList()).data.data
      if (!localStorage.getItem('order_statuses')) localStorage.setItem('order_statuses', JSON.stringify(this.statusOptions))
    },
    onClickViewOrder(id) {
      this.$refs.ManageStatus.openModal(id)
    },
    async getStatusList() {
      this.dataLoading = true
      this.items = []
      try {
        // eslint-disable-next-line no-underscore-dangle
        const { data } = (await orderRepository.getStatusManaegeList(this.page, this.perPage, this.filterQuery))
        this.items = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async getStatusListNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        const { data } = (await orderRepository.getFullStatusList())
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    onClickRefresh() {
      this.getStatusList()
    },
    handleChangePage(page) {
      this.page = page
      this.getStatusList()
    },
    applyFilters() {
      this.getStatusList()
    },
    clearFilters() {
      this.selectedStatusFilters = ''
      this.filters = {}
      this.$nextTick(() => {
        this.getStatusList()
      })
    },
  },
}
</script>

<style lang="scss">
  @import '../@core/scss/vue/libs/vue-select.scss';
</style>
